var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.productData, function (product, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "borderBottom",
          staticStyle: { padding: "10px 0" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { staticStyle: { "padding-top": "5px" }, attrs: { span: 4 } },
                [
                  _c(
                    "div",
                    { staticClass: "bg-purple fs13" },
                    [
                      product.cmasterUrl === undefined
                        ? _c("el-image", { staticClass: "custom-empty" }, [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      product.cmasterUrl
                        ? _c("el-image", {
                            attrs: { src: product.cmasterUrl, fit: "fit" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "textCenter" }, [_vm._v("零售价")]),
                  _c("div", { staticClass: "textCenter" }, [
                    _vm._v(_vm._s(product.nprice)),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "div",
                    { staticClass: "bg-purple-light fs13" },
                    [
                      _c(
                        "el-link",
                        {
                          staticStyle: { color: "purple" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleGoodsInfo(product.sid)
                            },
                          },
                        },
                        [_vm._v(_vm._s(product.cname))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品SKU:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v(_vm._s(product.csku)),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品条码:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v(_vm._s(product.ccode)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品库位:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v("1-2-3"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品库存:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v("123"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("可用库存:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v("456"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品规格:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v(_vm._s(product.cspecification)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "bg-purple fs13" }, [
                          _vm._v("货品类别:"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("div", { staticClass: "bg-purple-light fs13" }, [
                          _vm._v(_vm._s(product.rsort)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }