<template>
  <!-- 货品信息卡片-->
  <div>
    <div v-for="(product, index) in productData" :key="index" class="borderBottom" style="padding: 10px 0">
      <el-row :gutter="10">
        <el-col :span="4" style="padding-top: 5px">
          <div class="bg-purple fs13">
            <el-image v-if="product.cmasterUrl === undefined" class="custom-empty">
              <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
            </el-image>
            <el-image v-if="product.cmasterUrl" :src="product.cmasterUrl" fit="fit"> </el-image>
          </div>
          <div class="textCenter">零售价</div>
          <div class="textCenter">{{ product.nprice }}</div>
        </el-col>
        <el-col :span="20">
          <div class="bg-purple-light fs13">
            <el-link type="primary" style="color: purple" @click="handleGoodsInfo(product.sid)">{{ product.cname }}</el-link>
          </div>
          <el-row>
            <el-col :span="4">
              <div class="bg-purple fs13">货品SKU:</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple-light fs13">{{ product.csku }}</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple fs13">货品条码:</div>
            </el-col>
            <el-col :span="12">
              <div class="bg-purple-light fs13">{{ product.ccode }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <div class="bg-purple fs13">货品库位:</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple-light fs13">1-2-3</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple fs13">货品库存:</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple-light fs13">123</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple fs13">可用库存:</div>
            </el-col>
            <el-col :span="4">
              <div class="bg-purple-light fs13">456</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <div class="bg-purple fs13">货品规格:</div>
            </el-col>
            <el-col :span="20">
              <div class="bg-purple-light fs13">{{ product.cspecification }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <div class="bg-purple fs13">货品类别:</div>
            </el-col>
            <el-col :span="20">
              <div class="bg-purple-light fs13">{{ product.rsort }}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsCard",
  props: {
    productData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      size: "",
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      urls: [],
    };
  },
  mounted() {},
  methods: {
    handleGoodsInfo(sid) {
      this.$router.push({ path: "/goodsInfo", query: { sid: sid } });
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss">
.custom-empty {
  background-color: #f7f7f7;
  width: 61px;
  height: 38px;
}
.image-slot {
  padding-left: 25px;
  padding-top: 11px;
}
</style>
